import './App.css';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from 'react-router-dom';
import Navbar from './components/Navbar';
import Services from './pages/Services';
import About from './pages/About';
import Home from './pages/Home';
import Account from './pages/Account';
import Contact from './pages/Contact';
import Navbar2 from './components/Navbar2';
import ErrorPage from './pages/ErrorPage';
import Footer from './components/Footer';
import { ReactComponent as Logo1 } from './assets/skedaddle-services-logo-1.svg';
import { ReactComponent as MobileMenu } from './assets/mobile-menu.svg';
import Modal from './components/Modal';
import Login from './pages/Login';

function App() {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mobileHidden, setMobileHidden] = useState(false);
  const [showModalLogin, setShowModalLogin] = useState(false);

  /*//! **********************************************************************
    //! UseEffect for fetching data for job-category...
    //! *********************************************************************/
  // useEffect(() => {
  //   setLoading(true);

  //   const fetchedServices = async() => {

  //     try {
  //       const res = await Firebase
  //       .firestore()
  //       .collection('job-category')
  //       .get();
  //       console.log("FB job-category fetch results >>>>>>", res);

  //       let data = {title: "Not Found"};

  //       if(res.exists) {
  //         data = res.data();
  //       }

  //       setCategory(data);
  //       setLoading(false);
  //       // END OF TRY
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }

  //   fetchedServices();
  // }, []);

  //! Mobile menu button
  const handleMobileMenu = () => {
    // Flip the boolean value of mobileHidden
    // to display the mobile menu.
    setMobileHidden(!mobileHidden);
  };

  // Handle when Login is clicked - open a modal.
  const handleLoginModal = () => {
    setShowModalLogin(true);
  };

  return (
    <>
      <Router>
        <nav className="text-white bg-[#36096dcf]">

          <div className="max-w-6xl mx-auto px-5">
            <div className="flex justify-between">
              <div className="flex ">
                <Link to="/">
                  <Logo1 className="flex h-15 w-15 hover:animate-bounce " />
                </Link>
              </div>

              {/* Let side of Navbar */}
              <div className="flex items-center">
                {/*//! Regular Nav menu **************************************/}
                <div className="hidden md:block  space-x-4 text-sm">
                  <NavLink
                    to="/"
                    className={`hover:text-[#37d5d6] transition duration-300 [&.active]:text-[#37d5d6]`}
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/about"
                    className="hover:text-[#37d5d6] transition duration-300 [&.active]:text-[#37d5d6]"
                  >
                    About
                  </NavLink>
                  <NavLink
                    to="/services"
                    className="hover:text-[#37d5d6] transition duration-300 [&.active]:text-[#37d5d6]"
                  >
                    Services
                  </NavLink>
                  <NavLink
                    to="/account"
                    className="hover:text-[#37d5d6] transition duration-300 [&.active]:text-[#37d5d6]"
                  >
                    Account
                  </NavLink>
                  <NavLink
                    to="/contact"
                    className="hover:text-[#37d5d6] transition duration-300 [&.active]:text-[#37d5d6]"
                  >
                    Contact
                  </NavLink>
                </div>
              </div>

              {/*//! Right side of Navbar ************************************/}
              <div className="flex items-center">
                <button
                  className="hidden md:block border border-white rounded-xl p-2 font-light text-xs hover:text-[#37d5d6] hover:border-[#37d5d6] transition duration-300 focus:ring-2 focus:ring-[#37d5d6] focus:text-[#37d5d6] "
                  onClick={handleLoginModal}
                >
                  Login/Signup
                </button>
              </div>
            </div>

            {/*//! Mobile Button Right Side *********************************/}

            <div className="md:hidden flex justify-end ">
              <button
                className="text-white "
                onClick={() => handleMobileMenu()}
              > 
                {/* //.. Mobile Hamburger Menu - SVG  */}
                <MobileMenu className='text-white w-6 h-6' />
              </button>
            </div>
          </div>

          {/*//! Mobile Nav **************************************************/}
          {mobileHidden && (
            <>
              <div className="mobile-menu md:hidden text-[10px]">
                <NavLink
                  to="/"
                  className={`px-2 py-2 block hover:text-[#37d5d6] transition duration-300 [&.active]:text-[#37d5d6]`}
                >
                  Home
                </NavLink>
                <NavLink
                  to="/about"
                  className="px-2 py-2 block hover:text-[#37d5d6] transition duration-300 [&.active]:text-[#37d5d6]"
                >
                  About
                </NavLink>
                <NavLink
                  to="/services"
                  className="px-2 py-2 block hover:text-[#37d5d6] transition duration-300 [&.active]:text-[#37d5d6]"
                >
                  Services
                </NavLink>
                <NavLink
                  to="/account"
                  className="px-2 py-2 block hover:text-[#37d5d6] transition duration-300 [&.active]:text-[#37d5d6]"
                >
                  Account
                </NavLink>
                <NavLink
                  to="/contact"
                  className="px-2 py-2 block hover:text-[#37d5d6] transition duration-300 [&.active]:text-[#37d5d6]"
                >
                  Contact
                </NavLink>
                <div className="flex items-center">
                <button
                  className=" border border-white rounded-xl p-1 font-light  hover:text-[#37d5d6] hover:border-[#37d5d6] transition duration-300 focus:ring-2 focus:ring-[#37d5d6] focus:text-[#37d5d6] mb-2 ml-1"
                  onClick={handleLoginModal}
                >
                  Login/Signup
                </button>
              </div>
              </div>
            </>
          )}
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/account" element={<Account />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      </Router>

      <Modal isOpen={showModalLogin} onClose={() => setShowModalLogin(false)}>
        <Login />
      </Modal>
    </>
  );
}

export default App;
