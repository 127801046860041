import React from 'react';
import SkedaddleLogo1 from '../assets/skedaddle-services-logo-1.svg';

const Navbar = () => {
  return (
    <nav className="p-6 flex items-center  content-center bg-[#36096D] text-white pr-5 pl-5  ">
      <div className="flex">
        <img
          src={SkedaddleLogo1}
          alt="Skedaddle Services Logo"
          className="flex"
        />
      </div>
      <div className='flex justify-items-end'>
        {/* <a
          className="text-2xl hover:border-b-2 border-b-blue-300 h-full p-1  "
          href="/"
        ></a> */}
        <ul className="flex flex-row gap-5">
          <CustomLink className=" " href="/">
            Home
          </CustomLink>
          <CustomLink className="" href="/about">
            About
          </CustomLink>
          <CustomLink className="" href="/services">
            Services
          </CustomLink>
          <CustomLink className="" href="/myaccount">
            My Account
          </CustomLink>
          <CustomLink className="" href="/contact">
            Contact
          </CustomLink>
        </ul>
      </div>
    </nav>
  );
};

function CustomLink({ href, children, ...props }) {
  const path = window.location.pathname;
  return (
    <li className={path === href ? 'active custom-link' : ''}>
      <a href={href} {...props}>
        {children}
      </a>
    </li>
  );
}

export default Navbar;
