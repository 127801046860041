import React, { useState } from 'react';
import Modal from './Modal';
import Contact from '../pages/Contact';

const Footer = () => {
  const [showModalContactUs, setShowModalContactUs] = useState(false);



  // Handle when Contact Us is clicked - open a modal.
  const handleContactUs = () => {
    setShowModalContactUs(true);
  }


  return (
    <>
      <div className="bg-[#36096dcf] flex items-center h-20 text-white text-lg p-3 justify-around ">
      <button className="border border-white rounded-xl text-[#36096dcf] font-[575] bg-white p-2 text-xs hover:text-[#37d5d6] hover:border-[#37d5d6] transition duration-300 focus:ring-2 focus:ring-[#37d5d6] focus:text-[#37d5d6] tracking-wide"
        onClick={handleContactUs}
      >
          Contact Us Today!
        </button>

        Interested in Learning More?

      </div>
      <div className=" bg-[#36096Da8] text-sm h-15 p-5 text-white flex justify-center items-center">
        &copy; 2022 - 2030 ZWDesigns & TUTTLE
      </div>

      <Modal isOpen={showModalContactUs} onClose={() => setShowModalContactUs(false)}>
          <Contact />
        </Modal>
    </>
  );
};

export default Footer;
