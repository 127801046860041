import React, { useState } from 'react';
import { ReactComponent as Logo1 } from '../assets/skedaddle-services-logo-1.svg';

const Contact = () => {
  const handleSubmit = () => {
    console.log('Submit button clicked!');
  };

  return (
    <div className="min-h-[55vh] rounded-t-xl">
      <div className="bg-[#876EA8] min-h-40 rounded-t-xl">
        <Logo1 className="pl-3 w-55" />
      </div>
      <div className="m-10 indent-8">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet illo
        excepturi alias. Qui, error harum possimus natus quis labore nostrum
        asperiores corporis esse! Nisi reiciendis, similique expedita molestias
        fugiat, alias incidunt consequatur nihil voluptatibus minus minima
        inventore! Aliquam modi sit delectus sapiente. Magnam quas quisquam et
        non ipsum, atque sint nobis officia minima odit maxime.
      </div>

      {/*//! Middle HR Line and text */}
      <div className="inline-flex items-center justify-center w-full">
        <hr className="w-[80%] h-px my-8 bg-[#36096D44] border-0" />
        <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-[#EEEEF4] left-1/2">
          Contact Us
        </span>
      </div>

      <div className="flex flex-col items-center">
        <div className="mt-5">
          <label className="pr-1 " htmlFor="name">
            Name:{' '}
          </label>
          <input
            className="border border-[#36096D] rounded-md p-1/5 pl-1 mb-2"
            type="text"
            placeholder="Name"
          />
        </div>
        <div>
          <label className="pr-2" htmlFor="name">
            Email:{' '}
          </label>
          <input
            className="border border-[#36096D] rounded-md p-1/5 pl-1 mb-2"
            type="text"
            placeholder="Email"
          />
        </div>
        <div>
          <label className="pr-1" htmlFor="name">
            Phone:{' '}
          </label>
          <input
            className="border border-[#36096D] rounded-md p-1/5 pl-1"
            type="text"
            placeholder="Phone"
          />
        </div>

        <div className="mt-5">
          <button
            className="border border-[#36096D] rounded-md px-3 py-1 hover:text-white hover:bg-[#36096D] transition duration-300 active:text-lg active:ring-2 ring-blue-300"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
