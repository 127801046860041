import React, { useState } from 'react';
import { ReactComponent as Logo1 } from '../assets/skedaddle-services-logo-1.svg';
import Modal from '../components/Modal';
import Login from './Login';

const Signup = () => {
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [showModalSignup, setShowModalSignup] = useState(false);

  const handleLoginModal = (e) => {
    setShowModalLogin(true);
    setShowModalSignup(false);
  };

  return (
    <div className="min-h-[55vh] rounded-t-xl">
      {/*//! Header with Logo on Modal. */}
      <div className="bg-[#876EA8] min-h-40 rounded-t-xl">
        <Logo1 className="pl-3 w-55" />
      </div>

      <div className="bg-grey-lighter min-h-screen/2 flex flex-col ">
        <div className="container max-w-sm  mx-auto mt-4 mb-4 flex-1 flex flex-col items-center justify-center px-2 ">
          {/* Inside Modal area. */}
          <div className="bg-[#EEEEF4] px-6 py-8 rounded shadow-lg shadow-[#9574bd] text-black w-full">
            {/* Inside form for input. */}

            {/*//! Middle HR Line and text */}
            <div className="inline-flex items-center justify-center w-full">
              <hr className="w-[80%] h-px my-8 bg-[#36096D44] border-0" />
              <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-[#EEEEF4] left-1/2">
                Signup
              </span>
            </div>
            <input
              type="text"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="fullname"
              placeholder="Full Name"
            />

            <input
              type="text"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="email"
              placeholder="Email"
            />

            <input
              type="password"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="password"
              placeholder="Password"
            />
            <input
              type="password"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="confirm_password"
              placeholder="Confirm Password"
            />

            <button
              type="submit"
              className="w-full text-center py-3 rounded bg-[#876EA8] text-white hover:bg-green-dark focus:outline-none my-1"
            >
              Create Account
            </button>

            <div className="text-center text-sm text-grey-dark mt-4">
              By signing up, you agree to the
              <a
                className="no-underline border-b border-grey-dark  text-blue-400 text-grey-dark ml-1"
                href="#"
              >
                Terms of Service
              </a>{' '}
              and
              <a
                className="ml-1 no-underline border-b border-grey-dark text-grey-dark text-blue-400"
                href="#"
              >
                Privacy Policy
              </a>
            </div>
          </div>

          <div className="text-grey-dark mt-6">
            Already have an account?
            <p
              className="border-b border-blue text-blue-400 ml-1 hover:cursor-pointer"
              onClick={() => handleLoginModal()}
            >
              Login.
            </p>
          </div>
        </div>
      </div>
      <Modal isOpen={showModalLogin} onClose={() => setShowModalLogin(false)}>
        <Login />
      </Modal>
    </div>
  );
};

export default Signup;
