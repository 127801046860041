import React from 'react';

const ErrorPage = () => {
  return (
    <div className='relative min-h-screen  bg-[#36096D] text-white '>
      <div className=" flex flex-col absolute inset-y-80 inset-x-20">
        <p className="flex justify-center text-lg pulse">
          Something went wrong - please try again. If continues please email
          support:
        </p>
        <span className="text-blue-200 flex justify-center text-lg inset-40">
          <a href="/" className='animate-bounce'>ZWDesigns@test.com</a>
        </span>

      </div>
    </div>
  );
};

export default ErrorPage;
