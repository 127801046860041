import React from 'react'
import { ReactComponent as Logo1 } from '../assets/skedaddle-services-logo-1.svg';


const Modal = ({isOpen, onClose, children}) => {
  if(!isOpen) return null;

  const handleClose = (e) => {
    if(e.target.id === 'modalWrapper') {
      onClose();
    }
  }


  return (
    <>
    <div className='fixed inset-0 bg-[#190a2c] bg-opacity-25 backdrop-blur-sm flex justify-center items-center'
    id="modalWrapper"
      onClick={handleClose}
    >
      <div className='md:w-[600px] w-[90%] mx-auto flex flex-col '>
        
        <button className='text-white text-xl hover:text-[#36096D] place-self-end'
          onClick={() => onClose()}
        >
          <span className=''>X</span>
        </button>
        
        <div className='bg-[#EEEEF4] rounded-xl shadow-lg shadow-[#9574bd] '>{children}</div>
        
      </div>
      
      </div>
    </>
  )
}

export default Modal